<script setup lang="ts">
</script>
<template>
  <div
    class="features"
    id="features"
  >
    <div class="features-content">
      <div class="features-content-text">
        <h1 class="features-content-text-title">Особенности <br>таргетированной <br>рекламы</h1>
        <p class="features-content-text-description">
          Соцсети – настоящий клад для рекламодателей, ведь эти <br>
          сайты посещают миллионы людей, которые любят делиться <br>
          информацией о себе.
        </p>
        <p class="features-content-text-description">
          Это значит, что благодаря анкетным данным можно легко <br>
        вычислить представителей вашей целевой аудитории, которые <br>
        действительно заинтересованы в вашем рекламном <br>
        предложении.
        </p>
        <p class="features-content-text-description">
          Показывая свои объявления только им, вы в несколько раз <br>
        быстрее достигнете своей цели.
        </p>
      </div>
      <div class="features-content-left">
        <img
          class="features-content-left-image"
          src="@/assets/images/Artboard.webp"
          alt="">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.features-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: get-vw(1150px);
  margin-left: auto;
  margin-right: auto;
}
.features {
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    &-left {
      width: 50%;
      &-image {
        margin-right: get-vw(-55px);
        margin-bottom: get-vw(-80px);
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: get-vw(50px);
      position: relative;
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: get-vw(50px);
        line-height: get-vw(65px);
        color: #000000;
        position: relative;
        margin-bottom: get-vw(20px);
      }
      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(18px);
        line-height: get-vw(28px);
        color: #000000;
        margin-top: get-vw(15px);
        white-space: nowrap;
      }
    }
    &-text::before {
      content: '';
      display: block;
      position: absolute;
      bottom: get-vw(-35px);
      //bottom: get-vw(-10px);
      width: 55%;
      border-bottom: get-vw(5px) solid #4C4EF9;
    }
  }
}
</style>
